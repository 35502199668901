<template>
  <div class="c-container c-container--small">
    <Tile class="p-4 mb-10">
      <div class="flex w-100">
        <Title class="mx-auto" size="large" title="Invite streamer" />
      </div>

      <div class="flex py-4 px-8 w-100">
        <InputText
          ref="input"
          label="Twitch handle"
          :value="contentCreatorHandle"
          @onChange="setContentCreatorHandle"
        />
      </div>

      <div class="flex py-4 px-8 w-100">
        <InputCheckbox
          :checked="autoAcceptCampaigns"
          label="Auto accept streamer in new campaigns"
          @toggle="setAutoAcceptCampaigns"
        />
      </div>

      <div class="flex justify-center items-center w-100">
        <Button kind="default" label="Save" @clicked="inviteStreamer" />
      </div>
    </Tile>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { Title, Button } from "cavea-design-system";
import log from "@/lib/log";

export default {
  name: "AddContentCreator",

  components: {
    Tile: () => import("@/components/Tile"),
    Title,
    Button,
    InputText: () => import("@/components/inputs/InputText"),
    InputCheckbox: () => import("@/components/inputs/InputCheckbox"),
  },

  data() {
    return {
      contentCreatorHandle: "",
      autoAcceptCampaigns: true,
    };
  },

  computed: {
    ...mapGetters(["getOrganization"]),
  },

  methods: {
    /**
     * @param {string} handle
     */
    setContentCreatorHandle(handle) {
      log(`setContentCreatorHandle ${handle}`);

      this.contentCreatorHandle = handle;
    },

    /**
     * @param {boolean} checked
     */
    setAutoAcceptCampaigns(checked) {
      log({ fn: "setAutoAcceptCampaigns", checked });

      this.autoAcceptCampaigns = !this.autoAcceptCampaigns;
    },

    /**
     * @summary send invite request to streamer
     */
    async inviteStreamer() {
      log("inviteStreamer");

      if (this.contentCreatorHandle) {
        const URL = `${process.env.VUE_APP_API_URL}/organization/invite`;

        const response = await axios
          .post(URL, {
            contentCreatorHandle: this.contentCreatorHandle,
            autoAcceptCampaigns: this.autoAcceptCampaigns,
            orgId: this.getOrganization._id,
          })
          .then(() => {
            this.$swal({
              icon: "success",
              title: `${this.contentCreatorHandle} has been invited. `,
              showConfirmButton: false,
              timer: 1000,
            });
          })
          .catch((error) => {
            console.error("inviteStreamer error", error);

            this.$swal({
              icon: "error",
              title: error?.response?.data?.msg || "Something went wrong, please try again.",
              timer: 800,
              showConfirmButton: false,
            });

            return error;
          });

        this.closeModal();

        log(response);
      }
    },

    closeModal() {
      this.contentCreatorHandle = "";

      this.$emit("closeAddContentCreator");
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  display: block;
}
</style>
